// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-path {
  display: flex;
  flex-direction: column;
}
.list-path a {
  color: #FFFFFF;
  text-decoration: none;
  font-weight: 300;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}
.list-path a:hover {
  text-decoration: underline;
}
.list-path .title {
  font-weight: 500;
  font-size: 1rem;
}
.list-path .see-more {
  cursor: pointer;
  font-weight: 300;
  border: none;
  outline: none;
  background-color: transparent;
  color: #FFFFFF;
  text-align: start;
}
.list-path .see-more:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/childrens/listPath/styles.scss","webpack://./src/assets/styles/_vars.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;AAGE;EACE,cCEI;EDDJ,qBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AADJ;AAGI;EACE,0BAAA;AADN;AAKE;EACE,gBAAA;EACA,eAAA;AAHJ;AAME;EACE,eAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,6BAAA;EACA,cCpBI;EDqBJ,iBAAA;AAJJ;AAMI;EACE,0BAAA;AAJN","sourcesContent":["@import 'assets/styles/vars';\n\n.list-path{\n  display: flex;\n  flex-direction: column;\n\n  a{\n    color: $white;\n    text-decoration: none;\n    font-weight: 300;\n    font-size: 0.9rem;\n    margin-bottom: 1rem;\n\n    &:hover{\n      text-decoration: underline;\n    }\n  }\n\n  .title{\n    font-weight: 500;\n    font-size: 1rem;\n  }\n\n  .see-more{\n    cursor: pointer;\n    font-weight: 300;\n    border: none;\n    outline: none;\n    background-color: transparent;\n    color: $white;\n    text-align: start;\n\n    &:hover{\n      text-decoration: underline;\n    }\n  }\n}","// colors\n$orange: #EE5E24;\n$deepBlue: #191948;\n$lightBlue: #EBF4FF;\n$gray: #E8E7E7;\n$blue: #253B74;\n$darkBlue: #384354;\n$extraGray: #929497;\n$lightGray: #F2F4F8;\n$white: #FFFFFF;\n$black: #000000;\n$blackMedium: #232830;\n$darkBlack: #121212;\n$lightBlack: #444449;\n$green: #6CC516;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
