// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  z-index: 998;
  opacity: 1;
}
.modal .content {
  background-color: #FFFFFF;
  margin: 0.5rem;
  padding: 3.5rem 1.5rem 3.5rem;
  border-radius: 8px;
  width: 100%;
  position: relative;
}
@media (min-width: 360px) {
  .modal .content {
    width: 480px;
  }
}
.modal .content .close {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/modal/styles.scss","webpack://./src/assets/styles/_vars.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,oCAAA;EACA,kCAAA;UAAA,0BAAA;EACA,YAAA;EACA,UAAA;AADF;AAGE;EACE,yBCRI;EDSJ,cAAA;EACA,6BAAA;EACA,kBAAA;EACA,WAAA;EACA,kBAAA;AADJ;AAGI;EARF;IASI,YAAA;EAAJ;AACF;AAEI;EACE,kBAAA;EACA,aAAA;EACA,WAAA;AAAN","sourcesContent":["@import 'assets/styles/vars';\n\n.modal{\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgba(0,0,0,.6);\n  backdrop-filter: blur(2px);\n  z-index: 998;\n  opacity: 1;\n\n  .content{\n    background-color: $white;\n    margin: 0.5rem;\n    padding: 3.5rem 1.5rem 3.5rem;\n    border-radius: 8px;\n    width: 100%;\n    position: relative;\n\n    @media (min-width: 360px) {\n      width: 480px;\n    }\n\n    .close{\n      position: absolute;\n      right: 1.5rem;\n      top: 1.5rem;\n    }\n  }\n}","// colors\n$orange: #EE5E24;\n$deepBlue: #191948;\n$lightBlue: #EBF4FF;\n$gray: #E8E7E7;\n$blue: #253B74;\n$darkBlue: #384354;\n$extraGray: #929497;\n$lightGray: #F2F4F8;\n$white: #FFFFFF;\n$black: #000000;\n$blackMedium: #232830;\n$darkBlack: #121212;\n$lightBlack: #444449;\n$green: #6CC516;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
