// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-container {
  position: relative;
}

.tooltip {
  display: none;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  z-index: 998;
}

.tooltip-trigger:hover + .tooltip {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/tooltip/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".tooltip-container {\n  position: relative;\n}\n\n.tooltip {\n  display: none;\n  position: absolute;\n  background-color: #333;\n  color: #fff;\n  padding: 5px;\n  border-radius: 4px;\n  z-index: 998;\n}\n\n.tooltip-trigger:hover + .tooltip {\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
