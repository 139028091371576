// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.svg {
  transition: all 0.3s ease-in-out;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/components/icon/styles.scss"],"names":[],"mappings":"AAEA;EACE,gCAAA;EACA,sBAAA;AADF","sourcesContent":["@import 'assets/styles/vars';\n\n.svg{\n  transition: all 0.3s ease-in-out;\n  vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
