// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.information .container {
  padding: 2rem 1rem;
}
.information .container * {
  margin-bottom: 0.5rem;
}
.information .container h1 {
  font-size: 1.8rem;
  color: #121212;
}
.information .container h2 {
  font-size: 1.7rem;
  color: #121212;
}
.information .container h3 {
  font-size: 1.6rem;
  color: #121212;
}
.information .container h4 {
  font-size: 1.5rem;
  color: #121212;
}
.information .container h5 {
  font-size: 1.4rem;
  color: #121212;
}
.information .container h6 {
  font-size: 1.3rem;
  color: #121212;
}
.information .container span {
  font-size: inherit;
}
.information .container a,
.information .container ul,
.information .container ol,
.information .container p,
.information .container li {
  font-size: 1rem;
  color: #444449;
}
.information .container ul, .information .container ol {
  margin-left: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/information/styles.scss","webpack://./src/assets/styles/_vars.scss"],"names":[],"mappings":"AAGE;EACE,kBAAA;AAFJ;AAII;EACE,qBAAA;AAFN;AAKI;EACE,iBAAA;EACA,cAAA;AAHN;AAMI;EACE,iBAAA;EACA,cCLM;ADCZ;AAOI;EACE,iBAAA;EACA,cCVM;ADKZ;AAQI;EACE,iBAAA;EACA,cCfM;ADSZ;AASI;EACE,iBAAA;EACA,cCpBM;ADaZ;AAUI;EACE,iBAAA;EACA,cCzBM;ADiBZ;AAWI;EACE,kBAAA;AATN;AAYI;;;;;EAKE,eAAA;EACA,cCrCO;AD2Bb;AAaI;EACE,iBAAA;AAXN","sourcesContent":["@import 'assets/styles/vars';\n\n.information{\n  .container{\n    padding: 2rem 1rem;\n\n    *{\n      margin-bottom: 0.5rem;\n    }\n\n    h1{\n      font-size: 1.8rem;\n      color: $darkBlack;\n    }\n\n    h2{\n      font-size: 1.7rem;\n      color: $darkBlack;\n    }\n\n    h3{\n      font-size: 1.6rem;\n      color: $darkBlack;\n    }\n\n    h4{\n      font-size: 1.5rem;\n      color: $darkBlack;\n    }\n\n    h5{\n      font-size: 1.4rem;\n      color: $darkBlack;\n    }\n\n    h6{\n      font-size: 1.3rem;\n      color: $darkBlack;\n    }\n\n    span{\n      font-size: inherit;\n    }\n\n    a,\n    ul,\n    ol,\n    p,\n    li{\n      font-size: 1rem;\n      color: $lightBlack;\n    }\n\n    ul, ol{\n      margin-left: 1rem;\n    }\n  }\n}","// colors\n$orange: #EE5E24;\n$deepBlue: #191948;\n$lightBlue: #EBF4FF;\n$gray: #E8E7E7;\n$blue: #253B74;\n$darkBlue: #384354;\n$extraGray: #929497;\n$lightGray: #F2F4F8;\n$white: #FFFFFF;\n$black: #000000;\n$blackMedium: #232830;\n$darkBlack: #121212;\n$lightBlack: #444449;\n$green: #6CC516;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
