// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-mobile .content {
  display: grid;
  grid-template-rows: auto 1fr;
}
.navbar-mobile .top {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}
.navbar-mobile .top .icon-menu {
  margin-right: 1rem;
}
.navbar-mobile .top .logo {
  vertical-align: middle;
  width: 85px;
}
.navbar-mobile .body {
  overflow-y: auto;
}
.navbar-mobile .body .body-search {
  display: flex;
  flex-direction: column;
}
.navbar-mobile .body .body-search .title {
  font-size: 0.9rem;
  padding: 0.6rem 1rem;
}
.navbar-mobile .body .body-search .result {
  overflow: hidden;
  padding: 0.6rem 1rem;
  color: #121212;
  text-decoration: none;
  font-weight: 500;
}
.navbar-mobile .body .body-search .result:hover {
  background-color: #E8E7E7;
}`, "",{"version":3,"sources":["webpack://./src/components/header/childrens/navbarMobile/styles.scss","webpack://./src/assets/styles/_vars.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,4BAAA;AAFJ;AAKE;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;AAHJ;AAKI;EACE,kBAAA;AAHN;AAMI;EACE,sBAAA;EACA,WAAA;AAJN;AAQE;EACE,gBAAA;AANJ;AAQI;EACE,aAAA;EACA,sBAAA;AANN;AAQM;EACE,iBAAA;EACA,oBAAA;AANR;AASM;EACE,gBAAA;EACA,oBAAA;EACA,cC1BI;ED2BJ,qBAAA;EACA,gBAAA;AAPR;AASQ;EACE,yBCvCH;ADgCP","sourcesContent":["@import 'assets/styles/vars';\n\n.navbar-mobile{\n  .content{\n    display: grid;\n    grid-template-rows: auto 1fr;\n  }\n\n  .top{\n    display: flex;\n    align-items: center;\n    padding: 0.5rem 1rem;\n\n    .icon-menu{\n      margin-right: 1rem;\n    }\n\n    .logo{\n      vertical-align: middle;\n      width: 85px;\n    }\n  }\n\n  .body{\n    overflow-y: auto;\n\n    .body-search{\n      display: flex;\n      flex-direction: column;\n  \n      .title{\n        font-size: 0.9rem;\n        padding: 0.6rem 1rem;\n      }\n  \n      .result{\n        overflow: hidden;\n        padding: 0.6rem 1rem;\n        color: $darkBlack;\n        text-decoration: none;\n        font-weight: 500;\n  \n        &:hover{\n          background-color: $gray;\n        }\n      }\n    }\n  }\n}","// colors\n$orange: #EE5E24;\n$deepBlue: #191948;\n$lightBlue: #EBF4FF;\n$gray: #E8E7E7;\n$blue: #253B74;\n$darkBlue: #384354;\n$extraGray: #929497;\n$lightGray: #F2F4F8;\n$white: #FFFFFF;\n$black: #000000;\n$blackMedium: #232830;\n$darkBlack: #121212;\n$lightBlack: #444449;\n$green: #6CC516;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
