// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found .container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
@media screen and (min-width: 769px) {
  .not-found .container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.not-found .container .left {
  order: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.not-found .container .left h1 {
  font-size: 2rem;
  font-weight: 500;
}
.not-found .container .left p {
  margin: 1rem 0;
  font-weight: 300;
}
.not-found .container .left button {
  width: max-content;
}
@media screen and (min-width: 769px) {
  .not-found .container .left {
    order: 1;
    width: 50%;
    align-items: flex-start;
  }
  .not-found .container .left h1 {
    font-size: 2.5rem;
    max-width: 400px;
  }
  .not-found .container .left p {
    max-width: 400px;
  }
}
.not-found .container .right {
  order: 1;
}
.not-found .container .right img {
  width: 100%;
}
@media screen and (min-width: 769px) {
  .not-found .container .right {
    order: 2;
    width: 50%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/notFound/styles.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;AAFJ;AAII;EAPF;IAQI,mBAAA;IACA,8BAAA;IACA,mBAAA;IACA,aAAA;IACA,gBAAA;EADJ;AACF;AAGI;EACE,QAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AADN;AAGM;EACE,eAAA;EACA,gBAAA;AADR;AAIM;EACE,cAAA;EACA,gBAAA;AAFR;AAKM;EACE,kBAAA;AAHR;AAMM;EApBF;IAqBI,QAAA;IACA,UAAA;IACA,uBAAA;EAHN;EAKM;IACE,iBAAA;IACA,gBAAA;EAHR;EAMM;IACE,gBAAA;EAJR;AACF;AAOI;EACE,QAAA;AALN;AAOM;EACE,WAAA;AALR;AAQM;EAPF;IAQI,QAAA;IACA,UAAA;EALN;AACF","sourcesContent":["@import 'assets/styles/vars';\n\n.not-found{\n  .container{\n    padding: 1rem;\n    display: flex;\n    flex-direction: column;\n    margin-top: 3rem;\n    margin-bottom: 3rem;\n\n    @media screen and (min-width: 769px){\n      flex-direction: row;\n      justify-content: space-between;\n      align-items: center;\n      margin-top: 0;\n      margin-bottom: 0;\n    }\n\n    .left{\n      order: 2;\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n\n      h1{\n        font-size: 2rem;\n        font-weight: 500;\n      }\n      \n      p{\n        margin: 1rem 0;\n        font-weight: 300;\n      }\n\n      button{\n        width: max-content;\n      }\n\n      @media screen and (min-width: 769px){\n        order: 1;\n        width: 50%;\n        align-items: flex-start;\n\n        h1{\n          font-size: 2.5rem;\n          max-width: 400px;\n        }\n\n        p{\n          max-width: 400px;\n        }\n      }\n    }\n    .right{\n      order: 1;\n\n      img{\n        width: 100%;\n      }\n\n      @media screen and (min-width: 769px){\n        order: 2;\n        width: 50%;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
