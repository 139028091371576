// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input {
  width: 100%;
}
.input .label {
  margin-bottom: 0.5rem;
  margin-left: 8px;
  display: block;
  font-size: 0.9rem;
}
.input .label.t-error {
  color: red;
}
.input input {
  border: 1px solid #C5C5C5;
  border-radius: 12px;
  padding: 0.85rem;
  width: 100%;
  font-weight: 300;
}
.input input:focus {
  outline: none;
  border-radius: 12px;
  border: 1px solid #121212;
}
.input input.t-error {
  border: 1px solid red;
}
.input .error {
  font-size: 0.7rem;
  margin-left: 8px;
  display: block;
  color: red;
  font-weight: 300;
  margin-top: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/input/styles.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;AADF;AAGE;EACE,qBAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;AADJ;AAGI;EACE,UAAA;AADN;AAKE;EACE,yBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;AAHJ;AAKI;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;AAHN;AAMI;EACE,qBAAA;AAJN;AAQE;EACE,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,UAAA;EACA,gBAAA;EACA,kBAAA;AANJ","sourcesContent":["@import 'assets/styles/vars';\n\n.input{\n  width: 100%;\n  \n  .label{\n    margin-bottom: 0.5rem;\n    margin-left: 8px;\n    display: block;\n    font-size: 0.9rem;\n\n    &.t-error{\n      color: red;\n    }\n  }\n\n  input{\n    border: 1px solid #C5C5C5;\n    border-radius: 12px;\n    padding: 0.85rem;\n    width: 100%;\n    font-weight: 300;\n\n    &:focus{\n      outline: none;\n      border-radius: 12px;\n      border: 1px solid $darkBlack;\n    }\n\n    &.t-error{\n      border: 1px solid red;\n    }\n  }\n\n  .error{\n    font-size: 0.7rem;\n    margin-left: 8px;\n    display: block;\n    color: red;\n    font-weight: 300;\n    margin-top: 0.5rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
