// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  z-index: 997;
  opacity: 1;
}
.sidebar .content {
  background: linear-gradient(92.87deg, #E5E5E5 -0.1%, #D9D9D9 57.99%, #CACACA 71.24%, #BCBCBC 88.38%, #B3B3B3 100%);
  height: 100%;
  width: 100%;
}
@media (min-width: 480px) {
  .sidebar .content {
    width: 350px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/styles.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,oCAAA;EACA,kCAAA;UAAA,0BAAA;EACA,YAAA;EACA,UAAA;AADF;AAGE;EACE,kHAAA;EAEA,YAAA;EACA,WAAA;AAFJ;AAII;EANF;IAOI,YAAA;EADJ;AACF","sourcesContent":["@import 'assets/styles/vars';\n\n.sidebar {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  display: flex;\n  justify-content: start;\n  align-items: center;\n  background-color: rgba(0,0,0,.6);\n  backdrop-filter: blur(1px);\n  z-index: 997;\n  opacity: 1;\n\n  .content{\n    background: linear-gradient(92.87deg, #E5E5E5 -0.1%, #D9D9D9 57.99%, #CACACA 71.24%, #BCBCBC 88.38%, #B3B3B3 100%);\n\n    height: 100%;\n    width: 100%;\n  \n    @media (min-width: 480px) {\n      width: 350px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
