import './styles.scss'

const Tooltip = () => {
  return (
    <div className="tooltip-container">
      <button className="tooltip-trigger">Pasa el cursor aquí</button>
      <div className="tooltip">¡Este es un tooltip de ejemplo!</div>
    </div>
  )
}

export default Tooltip