// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  cursor: pointer;
}
.button:hover {
  opacity: 0.85;
}`, "",{"version":3,"sources":["webpack://./src/components/button/styles.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;AADF;AAGE;EACE,aAAA;AADJ","sourcesContent":["@import 'assets/styles/vars';\n\n.button{\n  cursor: pointer;\n\n  &:hover{\n    opacity: 0.85;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
