// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_carousel_item {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 769px) {
  .home_carousel_item {
    max-height: 80vh;
  }
}
.home_carousel_item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AADF;AAGE;EALF;IAMI,gBAAA;EAAF;AACF;AAEE;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;AAAJ","sourcesContent":["@import 'assets/styles/vars';\n\n.home_carousel_item{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  @media screen and (min-width: 769px){\n    max-height : 80vh;\n  }\n\n  img{\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n    object-position: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
