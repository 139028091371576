// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-card .container {
  padding: 3rem 2rem;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  gap: 2rem;
}
@media screen and (min-width: 769px) {
  .info-card .container {
    grid-template-columns: repeat(2, 1fr);
    padding: 2.5rem;
    gap: 4rem;
  }
}
@media screen and (min-width: 1024px) {
  .info-card .container {
    gap: 8rem;
  }
}
.info-card .container .left {
  order: 2;
}
.info-card .container .left h2 {
  font-size: 1.8rem;
}
@media screen and (min-width: 769px) {
  .info-card .container .left h2 {
    font-size: 2rem;
  }
}
.info-card .container .left p {
  font-size: 0.9rem;
  font-weight: 300;
  margin: 1.5rem 0;
  line-height: 1.3rem;
}
@media screen and (min-width: 769px) {
  .info-card .container .left p {
    font-size: 1rem;
  }
}
@media screen and (min-width: 769px) {
  .info-card .container .left {
    order: 1;
  }
}
@media screen and (min-width: 1024px) {
  .info-card .container .left {
    max-width: 90%;
  }
}
.info-card .container .right {
  order: 1;
}
.info-card .container .right img {
  object-fit: cover;
  width: 100%;
  vertical-align: middle;
  border-radius: 5px;
}
@media screen and (min-width: 769px) {
  .info-card .container .right {
    order: 2;
    max-width: 420px;
    height: 239px;
  }
  .info-card .container .right img {
    height: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/infoCard/styles.scss"],"names":[],"mappings":"AAGE;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,0BAAA;EACA,SAAA;AAFJ;AAII;EAPF;IAQI,qCAAA;IACA,eAAA;IACA,SAAA;EADJ;AACF;AAGI;EAbF;IAcI,SAAA;EAAJ;AACF;AAEI;EACE,QAAA;AAAN;AAEM;EACE,iBAAA;AAAR;AAEQ;EAHF;IAII,eAAA;EACR;AACF;AAEM;EACE,iBAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;AAAR;AAEQ;EANF;IAOI,eAAA;EACR;AACF;AAEM;EAtBF;IAuBI,QAAA;EACN;AACF;AACM;EA1BF;IA2BI,cAAA;EAEN;AACF;AACI;EACE,QAAA;AACN;AACM;EACE,iBAAA;EACA,WAAA;EACA,sBAAA;EACA,kBAAA;AACR;AAEM;EAVF;IAWI,QAAA;IACA,gBAAA;IACA,aAAA;EACN;EACM;IACE,YAAA;EACR;AACF","sourcesContent":["@import 'assets/styles/vars';\n\n.info-card{\n  .container{\n    padding: 3rem 2rem;\n    display: grid;\n    align-items: center;\n    grid-template-columns: 1fr;\n    gap: 2rem;\n\n    @media screen and (min-width: 769px){\n      grid-template-columns: repeat(2, 1fr);\n      padding: 2.5rem;\n      gap: 4rem;\n    }\n\n    @media screen and (min-width: 1024px){\n      gap: 8rem;\n    }\n\n    .left{\n      order: 2;\n\n      h2{\n        font-size: 1.8rem;\n\n        @media screen and (min-width: 769px){\n          font-size: 2rem;\n        }\n      }\n\n      p{\n        font-size: 0.9rem;\n        font-weight: 300;\n        margin: 1.5rem 0;\n        line-height: 1.3rem;\n\n        @media screen and (min-width: 769px){\n          font-size: 1rem;\n        }\n      }\n\n      @media screen and (min-width: 769px){\n        order: 1;\n      }\n\n      @media screen and (min-width: 1024px){\n        max-width: 90%;\n      }\n    }\n\n    .right{\n      order: 1;\n\n      img{\n        object-fit: cover;\n        width: 100%;\n        vertical-align: middle;\n        border-radius: 5px;\n      }\n    \n      @media screen and (min-width: 769px){\n        order: 2;\n        max-width: 420px;\n        height: 239px;\n\n        img{\n          height: 100%;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
