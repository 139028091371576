// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textarea {
  width: 100%;
}
.textarea label {
  margin-bottom: 0.5rem;
  display: block;
  font-size: 0.9rem;
  margin-left: 8px;
}
.textarea textarea {
  border: 1px solid #C5C5C5;
  border-radius: 12px;
  padding: 1rem;
  width: 100%;
  height: 105px;
  resize: none;
  font-weight: 300;
}
.textarea textarea:focus {
  outline: none;
  border-radius: 12px;
  border: 1px solid #121212;
}`, "",{"version":3,"sources":["webpack://./src/components/textarea/styles.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;AADF;AAGE;EACE,qBAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;AADJ;AAIE;EACE,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,WAAA;EACA,aAAA;EACA,YAAA;EACA,gBAAA;AAFJ;AAII;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;AAFN","sourcesContent":["@import 'assets/styles/vars';\n\n.textarea{\n  width: 100%;\n\n  label{\n    margin-bottom: 0.5rem;\n    display: block;\n    font-size: 0.9rem;\n    margin-left: 8px;\n  }\n\n  textarea{\n    border: 1px solid #C5C5C5;\n    border-radius: 12px;\n    padding: 1rem;\n    width: 100%;\n    height: 105px;\n    resize: none;\n    font-weight: 300;\n\n    &:focus{\n      outline: none;\n      border-radius: 12px;\n      border: 1px solid $darkBlack;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
