// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox .wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}
.checkbox .wrapper input[type=checkbox] {
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.checkbox .wrapper input[type=checkbox].error {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid red;
  border-radius: 3px;
}
.checkbox .wrapper label {
  cursor: pointer;
  font-weight: 300;
  font-size: 0.9rem;
}
.checkbox .wrapper label .link {
  color: #121212;
  font-weight: 500;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/checkbox/styles.scss","webpack://./src/assets/styles/_vars.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,qBAAA;AAFJ;AAII;EACE,eAAA;EACA,WAAA;EACA,YAAA;AAFN;AAIM;EACE,gBAAA;EACA,wBAAA;EACA,qBAAA;EACA,qBAAA;EACA,kBAAA;AAFR;AAMI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AAJN;AAMM;EACE,cCjBI;EDkBJ,gBAAA;EACA,0BAAA;AAJR","sourcesContent":["@import 'assets/styles/vars';\n\n.checkbox{\n  .wrapper{\n    display: flex;\n    align-items: center;\n    gap: 1rem;\n    margin-bottom: 0.5rem;\n\n    input[type=\"checkbox\"]{\n      cursor: pointer;\n      width: 24px;\n      height: 24px;\n\n      &.error{\n        appearance: none;\n        -webkit-appearance: none;\n        -moz-appearance: none;\n        border: 1px solid red;\n        border-radius: 3px;\n      }\n    }\n\n    label{\n      cursor: pointer;\n      font-weight: 300;\n      font-size: 0.9rem;\n\n      .link{\n        color: $darkBlack;\n        font-weight: 500;\n        text-decoration: underline;\n      }\n    }\n  }\n}","// colors\n$orange: #EE5E24;\n$deepBlue: #191948;\n$lightBlue: #EBF4FF;\n$gray: #E8E7E7;\n$blue: #253B74;\n$darkBlue: #384354;\n$extraGray: #929497;\n$lightGray: #F2F4F8;\n$white: #FFFFFF;\n$black: #000000;\n$blackMedium: #232830;\n$darkBlack: #121212;\n$lightBlack: #444449;\n$green: #6CC516;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
