// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input_search-container {
  background-color: #FFFFFF;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  border-color: rgba(197, 197, 197, 0.67);
  border-style: solid;
  border-width: 1px;
  padding: 0 1rem;
  position: relative;
}
.input_search-container.br-all {
  border-radius: 10px;
}
.input_search-container.br-top {
  border-radius: 10px 10px 0 0;
}
.input_search-container .input {
  padding: 0.75rem;
  font-weight: 300;
  border: none;
  outline: none;
  width: 100%;
}
.input_search-container .close {
  width: 14px;
  height: 14px;
  display: flex;
}
.input_search-container .close svg {
  background-color: #191948;
  border-radius: 50%;
  padding: 0.1rem;
}
.input_search-container .body {
  position: absolute;
  left: 0;
  background-color: #FFFFFF;
  border: 1px solid #E8E7E7;
  z-index: 997;
  border-radius: 0 0 10px 10px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 0.9rem;
  font-weight: 300;
  max-height: 315px;
}`, "",{"version":3,"sources":["webpack://./src/components/inputSearch/styles.scss","webpack://./src/assets/styles/_vars.scss"],"names":[],"mappings":"AAEA;EACE,yBCMM;EDLN,WAAA;EACA,aAAA;EACA,oCAAA;EACA,mBAAA;EACA,uCAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;AADF;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,4BAAA;AAFJ;AAKE;EACE,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,WAAA;AAHJ;AAME;EACE,WAAA;EACA,YAAA;EACA,aAAA;AAJJ;AAMI;EACE,yBClCK;EDmCL,kBAAA;EACA,eAAA;AAJN;AAQE;EACE,kBAAA;EACA,OAAA;EACA,yBCpCI;EDqCJ,yBAAA;EACA,YAAA;EACA,4BAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;AANJ","sourcesContent":["@import 'assets/styles/vars';\n\n.input_search-container{\n  background-color: $white;\n  width: 100%;\n  display: grid;\n  grid-template-columns: auto 1fr auto;\n  align-items: center;\n  border-color: rgba(#C5C5C5, 0.67);\n  border-style: solid;\n  border-width: 1px;\n  padding: 0 1rem;\n  position: relative;\n  \n  &.br-all{\n    border-radius: 10px;\n  }\n\n  &.br-top{\n    border-radius: 10px 10px 0 0;\n  }\n\n  .input{\n    padding: 0.75rem;\n    font-weight: 300;\n    border: none;\n    outline: none;\n    width: 100%;\n  }\n\n  .close{\n    width: 14px;\n    height: 14px;\n    display: flex;\n\n    svg{\n      background-color: $deepBlue;\n      border-radius: 50%;\n      padding: 0.1rem;\n    }\n  }\n\n  .body{\n    position: absolute;\n    left: 0;\n    background-color: $white;\n    border: 1px solid $gray;\n    z-index: 997;\n    border-radius: 0 0 10px 10px;\n    width: 100%;\n    overflow-x: hidden;\n    overflow-y: auto;\n    font-size: 0.9rem;\n    font-weight: 300;\n    max-height: 315px;\n  }\n}","// colors\n$orange: #EE5E24;\n$deepBlue: #191948;\n$lightBlue: #EBF4FF;\n$gray: #E8E7E7;\n$blue: #253B74;\n$darkBlue: #384354;\n$extraGray: #929497;\n$lightGray: #F2F4F8;\n$white: #FFFFFF;\n$black: #000000;\n$blackMedium: #232830;\n$darkBlack: #121212;\n$lightBlack: #444449;\n$green: #6CC516;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
