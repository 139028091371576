// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading.complete {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading .wrapper-spinner {
  position: relative;
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading .spinner {
  top: 50%;
  left: 50%;
  width: 70px;
  height: 70px;
  border: 7px solid rgb(155, 213, 255);
  border-top: 7px solid #171d4a;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-top: -12px;
  margin-left: -12px;
  display: none;
}
.loading .wrapper-spinner .spinner {
  display: inline-block;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/loading/styles.scss","webpack://./src/assets/styles/_vars.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,yBCDI;EDEJ,aAAA;EACA,uBAAA;EACA,mBAAA;AAFJ;AAKE;EACE,kBAAA;EACA,kBAAA;EACA,6BAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAHJ;AAME;EACE,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,6BAAA;EACA,kBAAA;EACA,kCAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;AAJJ;AAOE;EACE,qBAAA;AALJ;AAQE;EACE;IACE,uBAAA;EANJ;EAQE;IACE,yBAAA;EANJ;AACF","sourcesContent":["@import 'assets/styles/vars';\n\n.loading{\n  &.complete{\n    z-index: 1000;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: $white;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  .wrapper-spinner {\n    position: relative;\n    padding: 10px 20px;\n    background-color: transparent;\n    border: none;\n    border-radius: 4px;\n    cursor: not-allowed;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  .spinner {\n    top: 50%;\n    left: 50%;\n    width: 70px;\n    height: 70px;\n    border: 7px solid rgb(155, 213, 255);\n    border-top: 7px solid #171d4a;\n    border-radius: 50%;\n    animation: spin 1s linear infinite;\n    margin-top: -12px; \n    margin-left: -12px;\n    display: none;\n  }\n\n  .wrapper-spinner .spinner {\n    display: inline-block;\n  }\n\n  @keyframes spin {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n}\n","// colors\n$orange: #EE5E24;\n$deepBlue: #191948;\n$lightBlue: #EBF4FF;\n$gray: #E8E7E7;\n$blue: #253B74;\n$darkBlue: #384354;\n$extraGray: #929497;\n$lightGray: #F2F4F8;\n$white: #FFFFFF;\n$black: #000000;\n$blackMedium: #232830;\n$darkBlack: #121212;\n$lightBlack: #444449;\n$green: #6CC516;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
