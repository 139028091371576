// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion {
  display: flex;
  flex-direction: column;
}
.accordion .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.accordion .title.active {
  background-color: #191948;
}
.accordion .title.active a {
  color: #FFFFFF;
  font-weight: 700;
}
.accordion .title a {
  font-size: 1.15rem;
  font-weight: 500;
  text-decoration: none;
  color: #191948;
}
.accordion .title a:hover {
  text-decoration: underline;
}
.accordion .body .item {
  padding: 1rem 0 1rem 2.5rem;
}
.accordion .body .item.active {
  background-color: #191948;
}
.accordion .body .item.active a {
  color: #FFFFFF;
  font-weight: 700;
}
.accordion .body .item a {
  font-size: 1.15rem;
  font-weight: 500;
  text-decoration: none;
  color: #191948;
}
.accordion .body .item a:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/accordion/styles.scss","webpack://./src/assets/styles/_vars.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;AAGE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,aAAA;AADJ;AAGI;EACE,yBCXK;ADUX;AAGM;EACE,cCPA;EDQA,gBAAA;AADR;AAKI;EACE,kBAAA;EACA,gBAAA;EACA,qBAAA;EACA,cCvBK;ADoBX;AAKM;EACE,0BAAA;AAHR;AASI;EACE,2BAAA;AAPN;AASM;EACE,yBCpCG;AD6BX;AASQ;EACE,cChCF;EDiCE,gBAAA;AAPV;AAWM;EACE,kBAAA;EACA,gBAAA;EACA,qBAAA;EACA,cChDG;ADuCX;AAWQ;EACE,0BAAA;AATV","sourcesContent":["@import 'assets/styles/vars';\n\n.accordion{\n  display: flex;\n  flex-direction: column;\n\n  .title{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 1rem;\n\n    &.active{\n      background-color: $deepBlue;\n\n      a{\n        color: $white;\n        font-weight: 700;\n      }\n    }\n\n    a{\n      font-size: 1.15rem;\n      font-weight: 500;\n      text-decoration: none;\n      color: $deepBlue;\n\n      &:hover {\n        text-decoration: underline;\n      }\n    }\n  }\n\n  .body{\n    .item{\n      padding: 1rem 0 1rem 2.5rem;\n\n      &.active{\n        background-color: $deepBlue;\n  \n        a{\n          color: $white;\n          font-weight: 700;\n        }\n      }\n\n      a{\n        font-size: 1.15rem;\n        font-weight: 500;\n        text-decoration: none;\n        color: $deepBlue;\n  \n        &:hover {\n          text-decoration: underline;\n        }\n      }\n    }\n  }\n}\n","// colors\n$orange: #EE5E24;\n$deepBlue: #191948;\n$lightBlue: #EBF4FF;\n$gray: #E8E7E7;\n$blue: #253B74;\n$darkBlue: #384354;\n$extraGray: #929497;\n$lightGray: #F2F4F8;\n$white: #FFFFFF;\n$black: #000000;\n$blackMedium: #232830;\n$darkBlack: #121212;\n$lightBlack: #444449;\n$green: #6CC516;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
