// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-desktop {
  background: transparent;
  width: 100%;
}
.navbar-desktop .list {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/header/childrens/navbarDesktop/styles.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,WAAA;AADF;AAGE;EACE,aAAA;AADJ","sourcesContent":["@import 'assets/styles/vars';\n\n.navbar-desktop{\n  background: transparent;\n  width: 100%;\n\n  .list{\n    display: flex;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
